/**
 * Get page localizations
 * @param  {Object} availableLocales  The current page's available localized pages
 * @return {Object}                   List of locales that must be built, depending of the environment
 */

export function getPageLocalizations(availableLocales) {
  if(!availableLocales || availableLocales === null) {
    return ''
  }

  const selectedLocales = JSON.parse(process.env.GATSBY_BUILD_LOCALES)
  let activeLocalizations = {data: []}
  if (process.env.GATSBY_ENV === 'prod') {
    selectedLocales.forEach((selectedLocale) => {
      availableLocales.data.forEach((availableLocale) => {
        if(availableLocale.attributes.locale === selectedLocale) {
          activeLocalizations.data.push({
            attributes: { 
              locale: availableLocale.attributes.locale, 
              slug: availableLocale.attributes.slug, 
              title: availableLocale.attributes.title
            }
          })
        }
      })
    })
  } else {
    activeLocalizations = availableLocales
  }

  return activeLocalizations
}
