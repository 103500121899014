import { dataClick } from './data-layer'

const triggerEventClick = (data) => {
  if (!process.env.GATSBY_DATALAYER) {
    return ''
  }

  // General page data
  const pageContext = data.pageContext
  const gameTitle = pageContext.dataLayer && pageContext.dataLayer.game ? pageContext.dataLayer.game : 'dead by daylight' // will fallback to dead by daylight by default
  const language = pageContext.locale
  const postType = pageContext.postType
  const pageTitle = pageContext.defaultLocaleTitle ? pageContext.defaultLocaleTitle : (pageContext.title ? pageContext.title : '')
  let postName = pageTitle.toLowerCase()
  let chapter = ''

  // Custom event variables
  const eventCategory = data.eventCategory
  const eventAction = data.eventAction
  const eventType = data.eventType
  const eventLabel = data.eventLabel ? data.eventLabel : null
  const eventElement = data.eventElement ? data.eventElement : null
  const eventTitle = data.eventTitle ? data.eventTitle : null

  // Store informations (used for store links tracking only)
  const platformType = data.platformType
  const vendor = data.vendor

  // Page type (home, news, chapter, etc.)
  // Use the default english titles only
  let section = postType
  if (postType && postType !== null) {
    if (postType === 'article' || postType === 'articles') {
      section = gameTitle === 'dead by daylight' ? 'latest news' : 'news'
    } else if (postType === 'page' || postType === 'pages') {
      section = pageTitle ? pageTitle.toLowerCase() : postType
    } else {
      section = postType
    }
  }

  // Add Chapter Number (For DbD)
  if ((postType === 'chapter' || postType === 'chapters') && pageContext.product && pageContext.product !== null) {
    postName = pageContext.product
    chapter = postName
  }

  // Add Chapter Name on Character pages (For DbD)
  if ((postType === 'character' || postType === 'characters') && pageContext.product && pageContext.product !== null) {
    chapter = pageContext.product
  }

  // Clean up slugs
  chapter = chapter.replaceAll('-', ' ')
  postName = postName.replaceAll('-', ' ')

  // Send the formated data to the dataLayer
  const formatedData = {
    eventCategory,
    eventAction,
    eventType,
    eventLabel,
    eventElement,
    eventTitle,
    language,
    section,
    gameTitle,
    postName,
    chapter,
    platformType,
    vendor
  }
  dataClick(formatedData)
}

export default triggerEventClick