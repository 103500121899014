import React, { useEffect, useContext } from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { LoadingContext } from '@base/utils/contexts/loader-context'
import loadingIcon from '@img/MyM-loader.gif'

const Loader = ({ pageContext }) => {
  const { loading, contentLoaded, initLoading, feedbackLoading } = useContext(LoadingContext)
  const [isLoading, setLoading] = loading
  const [isContentLoaded, setContentLoaded] = contentLoaded
  const [isInitialLoading, setInitialLoading] = initLoading
  let isLoadingFeedback = false;

  function closeLoader() {
    if (isInitialLoading) {
      setTimeout(function () {
        setInitialLoading(false)
        setLoading(false)
      }, 1000)
    } else {
      setTimeout(function () {
        setLoading(false)
      }, 300)
    }

    setTimeout(function() {
      isLoadingFeedback = false;
    }, 2000)
  }

  useEffect(() => {
    if (isContentLoaded === true) {
      closeLoader()
    } else {
      setTimeout(function () {
        closeLoader()
      }, 500)
    }
  }, [isContentLoaded])

  // Fallback to prevent the loader to stuck
  useEffect(() => {
    setTimeout(function () {
      if(isLoading && !isLoadingFeedback) {
        isLoadingFeedback = true;
        closeLoader()
      }
    }, 2000)
  }, [isLoading])

  return (
    <div
      id="pageLoader"
      className={`fixed top-0 left-0 w-full h-screen pointer-events-none opacity-100 transition-all duration-300 ${isInitialLoading ? 'z-[999999999]' : 'z-50'}`}
      style={{
        overflow: `hidden`,
        filter: `drop-shadow(5px 5px 10px rgba(0,0,0,0.75))`
      }}
    >
      {isInitialLoading ? (
        <>
          <div style={{
            position: `fixed`,
            left: 0,
            top: 0,
            pointerEvents: `initial`,
            clipPath: `polygon(0% 0%, 100% 0%, 100% 100%, calc(30% + 65px) 100%, 30% calc(100% - 50px), 0% calc(100% - 50px))`,
            height: `${isLoading ? '100vh' : '0vh'}`,
            width: `100%`,
            background: `linear-gradient(45deg, #0A171A -3.63%, #004242 100.22%)`,
            transition: `all 0.5s`,
            zIndex: 1
          }}>
            <StaticImage
              src="../../assets/img/MyM-yellow-dust-texture.png"
              alt=""
              className="h-auto w-full pointer-events-none bottom-0 left-0 gatsby-absolute opacity-50"
              loading="lazy"
            />
            <StaticImage
              src="../../assets/img/MyM-yellow-dust-texture-top.png"
              alt=""
              className="h-auto w-full pointer-events-none top-0 left-0 opacity-60"
              loading="lazy"
            />
            <img
              src={loadingIcon}
              className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 w-48 lg:w-96"
            />
          </div>
          <div style={{
            position: `fixed`,
            left: 0,
            bottom: 0,
            pointerEvents: `initial`,
            height: `${isLoading ? '10vh' : '0vh'}`,
            width: `100%`,
            background: `linear-gradient(45deg, #0A171A -3.63%, #004242 100.22%)`,
            transition: `all 0.5s`,
            zIndex: 0
          }} />
        </>
      ) : (
        <>
          <div style={{
            position: `absolute`,
            left: 0,
            top: 0,
            pointerEvents: `initial`,
            clipPath: `polygon(0% 0%, 100% 0%, 100% 100%, calc(30% + 65px) 100%, 30% calc(100% - 50px), 0% calc(100% - 50px))`,
            height: `${isLoading ? 'calc(50vh + 50px)' : '0vh'}`,
            width: `100%`,
            background: `linear-gradient(45deg, #0A171A -3.63%, #004242 100.22%)`,
            transition: `all 0.25s`
          }}>
            <StaticImage
              src="../../assets/img/MyM-yellow-dust-texture.png"
              alt=""
              className="h-auto w-full pointer-events-none bottom-0 left-0 gatsby-absolute opacity-50"
              loading="lazy"
            />
          </div>
          <div style={{
            position: `absolute`,
            left: 0,
            bottom: 0,
            pointerEvents: `initial`,
            clipPath: `polygon(0% 100%, 100% 100%, 100% calc(0% + 50px), calc(30% + 65px) calc(0% + 50px), 30% 0%, 0% 0%)`,
            height: `${isLoading ? '50vh' : '0vh'}`,
            width: `100%`,
            background: `linear-gradient(45deg, #0A171A -3.63%, #004242 100.22%)`,
            transition: `all 0.25s`
          }}>
            <StaticImage
              src="../../assets/img/MyM-yellow-dust-texture-top.png"
              alt=""
              className="h-auto w-full pointer-events-none top-0 left-0 opacity-60"
              loading="lazy"
            />
          </div>
        </>
      )}
    </div>
  )
}

export default Loader