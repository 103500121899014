/**
 * Format Game & DLC Related data
 */
export function getGameData(data) {
  if(!data) {
    return null
  }

  const gameData = data.game ? data.game?.edges[0]?.node : null
  const promoData = data.promotions ? data.promotions : null
  const stores = gameData?.stores ? gameData.stores : null
  const storeLogos = data.storeLogos?.edges

  // Game Stores links
  let gameLinks = []
  const mainGame = gameData.mainGame ? gameData.mainGame : null
  mainGame.forEach((product) => {
    gameLinks.push({ ...product, type: 'main-game', subType: 'digital' })
  })
  const dlcs = gameData.dlc ? gameData.dlc : null
  dlcs.forEach((product) => {
    product.dlc?.slug && product.arsenalLinks.length >= 1 && (
      gameLinks.push({ ...product, gameID: `${product.dlc?.slug}-arsenal-pack`, links: product?.arsenalLinks, type: 'dlc', subType: 'arsenal' })
    )
    product.dlc?.slug && product.collectionLinks.length >= 1 && (
      gameLinks.push({ ...product, gameID: `${product.dlc?.slug}-cosmetic-collection`, links: product?.collectionLinks, type: 'dlc', subType: 'cosmetic' })
    )
    product.dlc?.slug && product.bundleLinks.length >= 1 && (
      gameLinks.push({ ...product, gameID: `${product.dlc?.slug}-bundle`, links: product?.bundleLinks, type: 'dlc', subType: 'bundle' })
    )
  })

  // Physical Games (Asia only)
  const physicalGame = gameData.physicalGame ? gameData.physicalGame : null
  physicalGame.forEach((product) => {
    gameLinks.push({ ...product, type: 'main-game', subType: 'physical' })
  })

  // Stores Logos
  // TODO: Allow the usage of default store logos if none is defined in Strapi

  // CTA data
  const buySection = gameData.buyGameSection ? gameData.buyGameSection : null

  // Promotion Data
  const promoDiscounts = promoData !== null && promoData.promos ? promoData.promos : null
  const promoFreeWE = null

  const formatedGameData = {
    buySection: buySection,
    gameLinks: gameLinks,
    promos: {
      discounts: promoDiscounts,
      freeWE: promoFreeWE,
    },
    stores: stores
  }

  return formatedGameData
}