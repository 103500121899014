/**
 * Format Ratings Related data
 */
export function getRatingData(data) {
  if(!data) {
    return null
  }
  
  const formatedRatingsData = {
    ratings: data.global.edges[0].node.ratings.countryGroups,
    ratingLogos: data.ratingLogos.edges
  }
  return formatedRatingsData
}