
/**
 * Format Age Gate Related data
 */
export function getAgeGateData(data, pageData) {
  if(!data) {
    return null
  }

  const ageGate = data.global.edges[0].node.ageGate
  const backgroundImage = ageGate.backgroundImage
  const buttonLabel = ageGate.buttonLabel
  const cookieName = ageGate.cookieName
  const siteEnabled = ageGate.enabled ? ageGate.enabled : false // Enable the age gate globally by default (Pages, Videos & Forms)
  const pageEnabled = false // Enable full-page age gating 
  const errorMessage = ageGate.errorMessage
  const errorTitle = ageGate.errorTitle
  const title = ageGate.title
  const text = ageGate.text
  const logo = data.brand.edges[0].node.fullLogo

  const ageGateData = {
    backgroundImage: backgroundImage,
    buttonLabel: buttonLabel,
    cookieName: cookieName,
    siteEnabled: siteEnabled,
    pageEnabled: pageEnabled,
    errorMessage: errorMessage,
    errorTitle: errorTitle,
    title: title,
    text: text,
    logo: logo,
    classes: {
      modal: 'fixed top-0 left-0 w-full h-full flex justify-center items-center z-god',
    },
    cancellable: false,
    pageLock: true,
    video: {
      logo: null,
      classes: {
        modal: 'fixed lg:absolute top-0 left-0 w-full h-full flex justify-center items-center mobile:z-god md:z-god lg:z-10',
        backdrop: 'absolute w-10 lg:w-full h-10 lg:h-full bg-black flex justify-center items-center right-8 top-8 lg:right-0 lg:top-0',
        content: 'age-gate-wrapper absolute w-full lg:w-[96%] xl:w-3/4 h-full lg:h-[96%] xl:h-[85%] z-10 flex flex-col items-center justify-center bg-gradient-to-t from-black to-primary left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2  px-14 lg:px-0',
        form: 'flex flex-col lg:flex-row lg:mt-10 mb-0 items-center lg:items-end relative text-white font-display uppercase text-center w-full lg:w-auto',
        input: 'relative mt-2 mb-0 w-full text-center sm:w-36 h-20 text-base font-body tracking-wider',
        submit: 'button button-secondary button-narrow inline-flex items-center justify-center w-full md:w-auto h-20 lg:h-24 relative z-10 lg disabled lg:ml-[16px]',
        error: 'absolute top-8 inline-flex flex-row justify-between border border-solid border-red bg-black px-4 py-6 text-sm text-white',
      },
      cancellable: true,
      pageLock: false
    }
  }
  return ageGateData
}