import React, { useRef } from 'react'
import {
  motion,
  useScroll,
  useTransform,
  useSpring,
  useReducedMotion,
} from 'framer-motion'
import { StaticImage } from 'gatsby-plugin-image'
import Image from '@base/parts/image'
import AnimatedBackground from '@elements/animated-background'

const BuyGameExtension = (props) => {
  const section = useRef(null)
  
  if (!props.data) {
    return <></>
  }

  // UI elemnents animations
  const prefersReducedMotion = useReducedMotion()
  const { scrollYProgress } = useScroll({
    target: section,
    offset: ['0%', '100%'],
  })
  const translateX = useSpring(scrollYProgress, {
    stiffness: 70,
    damping: 30,
  })
  const topUIAnim = useTransform(translateX, [0, 1], [200, 500])

  return (
    <>
      {props.data.backgroundImage && props.data.backgroundImage !== null && (
        <AnimatedBackground ref={section} className="pointer-events-none absolute left-0 top-0 z-under h-full w-full">
          <Image
            media={props.data.backgroundImage}
            className={`buy-game-background h-[200%] w-full`}
          />
        </AnimatedBackground>
      )}
      <motion.div
        style={{
          x: !prefersReducedMotion && topUIAnim,
        }}
        className={`gatsby-absolute pointer-events-none z-0 -left-[250%] lg:-left-1/2 bottom-12 lg:-bottom-24`}
      >
        <StaticImage
          src="../../assets/img/MyM-texture-diagonal-stripe-large.png"
          alt=""
          className="gatsby-object-contain w-auto max-w-full h-[150px] lg:h-[350px]"
          loading="lazy"
        />
      </motion.div>
    </>
  )
}
export default BuyGameExtension
