exports.components = {
  "component---src-pages-js": () => import("./../../../src/pages/[...].js" /* webpackChunkName: "component---src-pages-js" */),
  "component---src-templates-article-js": () => import("./../../../src/templates/article.js" /* webpackChunkName: "component---src-templates-article-js" */),
  "component---src-templates-dlc-js": () => import("./../../../src/templates/dlc.js" /* webpackChunkName: "component---src-templates-dlc-js" */),
  "component---src-templates-error-page-js": () => import("./../../../src/templates/error-page.js" /* webpackChunkName: "component---src-templates-error-page-js" */),
  "component---src-templates-landing-page-js": () => import("./../../../src/templates/landing-page.js" /* webpackChunkName: "component---src-templates-landing-page-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-sitemap-page-js": () => import("./../../../src/templates/sitemap-page.js" /* webpackChunkName: "component---src-templates-sitemap-page-js" */)
}

