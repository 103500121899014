import React from 'react'
import SVG from 'react-inlinesvg'

import Image from '@base/parts/image'
import external from '@base/assets/icons/external-link.svg'

import PromoTag from './promo-tag'
import triggerEventClick from '@/utils/helpers/tracking/trigger-event-click'

const StoreLink = ({ index, pageContext, data, layout, className, eventData, children, logoOnly, logoSize }) => {
  const storeID = data.platformID // steam, epic, xbox, etc.
  const key = index || index === 0 ? `${layout}-${storeID}-${index}` : `${layout}-${storeID}`
  const platform = data.platformCategory ? data.platformCategory : data.platformType ? data.platformType : 'pc' // pc, console or mobile
  const purchaseState = data.purchaseState ? data.purchaseState : 'released' // released, pre-purchase, wishlist
  const promoData = data.promotion

  // Setup tracking values for dataLayer
  const eventAction = eventData?.eventAction && eventData.eventAction !== null ? eventData.eventAction : 'buy game'
  const eventCategory = eventData?.eventCategory && eventData.eventCategory !== null ? eventData.eventCategory : 'buy'
  const eventType = eventData?.eventType && eventData.eventType !== null ? eventData.eventType : 'purchaseFlow'
  const eventElement = eventData?.eventElement && eventData.eventElement !== null ? eventData.eventElement : null
  const eventTitle = eventData?.eventTitle && eventData.eventTitle !== null ? eventData.eventTitle : null

  // Track Click on button with GTM
  const trackClick = (storeID, platform, vendor) => {
    let dataLayer = {
      pageContext: pageContext,
      eventCategory: eventCategory,
      eventAction: eventAction,
      eventType: eventType,
      eventElement: eventElement,
      eventTitle: eventTitle,
      eventProduct: `${storeID !== 'base-game' ? storeID : null}`,
      platformType: platform,
      vendor: vendor
    }
    triggerEventClick(dataLayer)
  }

  // Logo Data
  let storeLogoSize = logoSize && logoSize === 'small' ? 'small' : 'large'
  let logoLarge = data.logoLarge
  let logoSmall = data.logoSmall
  let fullLogoSizes = ''
  let fullLogoArray = ''
  let iconLogoSizes = ''
  let iconLogoArray = ''

  // Default Logo Sizes - Sizes are based on Tailwind CSS height values (8, 10, 12, etc.)
  let fullLogoLarge = '14'
  let fullLogoSmall = '8'
  let iconLogoLarge = '8'
  let iconLogoSmall = '6'

  // Custom Logo Sizes 
  if (data.logoLargeSize && data.logoLargeSize !== null) {
    fullLogoSizes = data.logoLargeSize
    fullLogoArray = fullLogoSizes.split(',') // ["large", "small"]
    if (fullLogoArray[0]) {
      fullLogoLarge = fullLogoArray[0]
    }
    if (fullLogoArray[1]) {
      fullLogoSmall = fullLogoArray[1]
    }
  }
  if (data.logoSmallSize && data.logoSmallSize !== null) {
    iconLogoSizes = data.logoSmallSize
    iconLogoArray = iconLogoSizes.split(',') // ["large", "small"]
    if (iconLogoArray[0]) {
      iconLogoLarge = iconLogoArray[0]
    }
    if (iconLogoArray[1]) {
      iconLogoSmall = iconLogoArray[1]
    }
  }

  // Alternate Logo
  if (layout === 'cta' && data.logoAlternate) {
    logoLarge = data.logoAlternate
  }

  let logoData = { storeID, storeLogoSize, logoLarge, logoSmall, iconLogoSmall, iconLogoLarge, fullLogoLarge, fullLogoSmall }

  if (layout === 'list' || layout === 'categories') {
    return (
      <li
        className={`store-link-list-item ${storeID}`}
        key={key}
        title={data.link && data.link !== '#' ? '' : `Coming Soon on ${data.title}`}
      >
        <a
          href={!logoOnly && data.link && data.link !== '#' ? data.link : ''}
          title={!logoOnly && data.link && data.link !== '#' ? `Buy on ${data.title}` : ''}
          target={!logoOnly && data.link && data.link !== '#' ? "_blank" : ''}
          rel="noopener noreferrer"
          className={`flex items-center justify-between ${className ? className : ''} ${data.link && data.link !== '#' ? '' : 'pointer-events-none'}`}
          onClick={() => trackClick(storeID, platform, storeID + ' store')}
        >
          <div className="store-link-logo flex justify-center items-center">
            <Logo
              size={storeLogoSize === 'large' ? 'large' : 'small'} // Display small logos by default
              data={logoData}
              className={`block fill-white w-auto mr-8`}
            />
            <span>
              {data.title}
            </span>
          </div>
          <div className="flex items-center">
            {promoData && (
              <PromoTag
                data={promoData}
              />
            )}
            {data.link && data.link !== '#' ? (
              <SVG
                onError={error => console.log(error.message)}
                cacheRequests={true}
                preProcessor={code =>
                  code.replace(/fill=".*?"/g, 'fill="currentColor"')
                }
                src={external}
                className="externallink-indicator ml-6 w-8 h-8 sm:h-8 md:h-8 lg:h-8"
              />
            ) : (
              <span className="uppercase font-bold w-36 ml-20 inline-block">
                Coming Soon
              </span>
            )}
          </div>
        </a>
      </li>
    )
  }

  if (layout === 'list-state') {
    return (
      <li
        className={`store-link-list-state-item ${storeID} relative`}
        key={key}
      >
        <a
          href={!logoOnly && data.link && data.link !== '#' ? data.link : ''}
          className={`flex items-center justify-between ${className ? className : ''} ${!logoOnly && data.link && data.link !== '#' ? '' : 'pointer-events-none'}`}
          title={!logoOnly && data.link && data.link !== '#' ? `Buy on ${data.title}` : ''}
          target={!logoOnly && data.link && data.link !== '#' ? "_blank" : ''}
          rel="noopener noreferrer"
          key={`ctaLink${data.dataId}-${storeID}`}
          onClick={() => trackClick(storeID, platform, storeID + ' store')}
        >
          <div className="w-full flex justify-center items-center">
            {data.label}
            <Logo
              size={storeLogoSize === 'small' ? 'small' : 'large'}
              data={logoData}
              className={`block fill-white w-auto`}
            />
          </div>
          {children ? children : ''}
        </a>
      </li>
    )
  }

  if (layout === 'cta') {
    return (
      <a
        href={!logoOnly && data.link && data.link !== '#' ? data.link : ''}
        className={`store-link-cta-item ${storeID} flex items-center justify-between ${className ? className : ''} ${!logoOnly && data.link && data.link !== '#' ? '' : 'pointer-events-none'}`}
        title={!logoOnly && data.link && data.link !== '#' ? `Buy on ${data.title}` : ''}
        target={!logoOnly && data.link && data.link !== '#' ? "_blank" : ''}
        rel="noopener noreferrer"
        key={key}
        onClick={() => trackClick(storeID, platform, storeID + ' store')}
      >
        <div className="w-full flex justify-center items-center">
          {data.label}
          <Logo
            size={storeLogoSize === 'small' ? 'small' : 'large'}
            data={logoData}
            className={`block fill-white w-auto`}
          />
          {promoData && (
            <PromoTag
              data={promoData}
            />
          )}
        </div>
        {children ? children : ''}
      </a>
    )
  }

  // Display Stores as a list of small or large logos, with or without link
  if (layout === 'logo') {
    return (
      logoOnly ? (
        <Logo
          size={storeLogoSize === 'small' ? 'small' : 'large'}
          data={logoData}
          className={`block fill-white w-auto`}
          index={key}
        />
      ) : (
        <a
          href={!logoOnly && data.link && data.link !== '#' ? data.link : ''}
          title={!logoOnly && data.link && data.link !== '#' ? `Buy on ${data.title}` : ''}
          target={!logoOnly && data.link && data.link !== '#' ? "_blank" : ''}
          rel="noopener noreferrer"
          className={`store-link-logo ${storeID} flex items-center ${className ? className : ''} ${!logoOnly && data.link && data.link !== '#' ? '' : 'pointer-events-none'}`}
          onClick={() => trackClick(storeID, platform, storeID + ' store')}
          key={key}
        >
          <Logo
            size={storeLogoSize === 'small' ? 'small' : 'large'}
            data={logoData}
            className={`block fill-white w-auto`}
          />
        </a>
      )
    )
  }

  return (
    <li
      className={`store-link-item ${storeID} inline-flex justify-center`}
      key={key}
      title={data.link && data.link !== '#' || logoOnly ? '' : `Coming Soon on ${data.title}`}
    >
      <a
        href={!logoOnly && data.link && data.link !== '#' ? data.link : ''}
        title={!logoOnly && data.link && data.link !== '#' ? `Buy on ${data.title}` : ''}
        target={!logoOnly && data.link && data.link !== '#' ? "_blank" : ''}
        rel="noopener noreferrer"
        className={`flex items-center ${className ? className : ''} ${!logoOnly && data.link && data.link !== '#' ? '' : 'pointer-events-none'}`}
        onClick={() => trackClick(storeID, platform, storeID + ' store')}
      >
        <Logo
          size={storeLogoSize === 'small' ? 'small' : 'large'}
          data={logoData}
          className={`block fill-white w-auto`}
        />
      </a>
    </li>
  )
}
export default StoreLink

// Store Logo
const Logo = (props) => {
  const data = props.data
  return (
    <Image
      key={props.index ? props.index : data.storeID}
      media={props.size === 'small' ? data.logoSmall : data.logoLarge}
      className={`${props.className} ${props.size === 'small' ? `h-${data.iconLogoSmall} md:h-${data.iconLogoLarge}` : `h-${data.fullLogoSmall} md:h-${data.fullLogoLarge}`}`}
      keepStyle
    />
  )
}