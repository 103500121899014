import React, { useRef } from 'react'
import { motion, useScroll, useTransform, useSpring, useReducedMotion } from 'framer-motion'

const AnimatedBackground = (props) => {
  const ref = useRef(null)
  const prefersReducedMotion = useReducedMotion()
  const { scrollYProgress } = useScroll({
    target: ref,
    offset: ["-100vh", "100vh"]
  })
  const translateY = useSpring(scrollYProgress, {
    stiffness: 50,
    damping: 30
  })
  const contentAnim = useTransform(translateY, [0, 1], [-100, 0])

  return (
    <div ref={ref} className="w-full h-full absolute overflow-hidden top-0 left-0 pointer-events-none">
      <motion.div
        className={props.className ? props.className : ''}
        style={{
          y: !prefersReducedMotion && contentAnim
        }}
      >
        {props.children}
      </motion.div>
    </div>
  )
}

export default AnimatedBackground
