/**
 * Data Layer
 * Track Clicks with Google Analytics
 * 
 * @param  {String} eventCategory   The click event category (buy, form, interaction, video, event)
 * @param  {String} eventAction     The click event action or description
 * @param  {String} eventType       The click event type/sub-category (main, purchaseFlow, form, character or chapter) 
 * @param  {String} eventElement    The click event location on the page (nav, footer, hero, etc.)
 * @param  {String} eventLabel      Custom click event label
 * @param  {String} language        Page language
 * @param  {String} section         Section/page title
 * @param  {String} gameTitle       Website Game title
 * @param  {String} postName        Custom Post title
 * @param  {String} chapter         The post chapter (if available)
 * @param  {String} platformType    The type of platform (pc or console)
 * @param  {String} vendor          The store name (steam store, microsoft store, etc.)
 */

export const dataClick = (data) => {
  const language = data.language
  const section = data.section
  const gameTitle = data.gameTitle
  const postName = data.postName
  const chapter = data.chapter
  const eventCategory = data.eventCategory
  const eventAction = data.eventAction
  const eventType = data.eventType
  const eventLabel = data.eventLabel
  const eventTitle = data.eventTitle
  const eventElement = data.eventElement && data.eventElement !== null ? `${section} ${data.eventElement}` : section
  const platformType = data.platformType
  const vendor = data.vendor

  // Default Object
  let dataObject = {
    'event': 'uaevent',
    'eventCategory': eventCategory,
    'eventAction': eventAction,
    'element': eventElement,
    'language': language
  }

  // Set the post type key and value
  let postNameKey = ''
  let postNameValue = ''
  if (postName && postName !== null) {
    postNameValue = postName
    if (section === 'chapters') {
      postNameKey = 'chapterName'
    } else if (section === 'characters') {
      postNameKey = 'characterName'
    }
  }

  // Set event informations depending of the event type (sub-category)
  if (eventType && eventType !== null) {
    let eventData = ''
    switch (eventType) {
      case 'main':
        if (postNameKey !== '' && postNameValue !== '') {
          eventData = {
            'eventLabel': gameTitle,
            'section': section,
            [postNameKey]: postNameValue
          }
        } else {
          eventData = {
            'eventLabel': gameTitle,
            'section': section
          }
        }
        break
      case 'interaction':
        eventData = {
          'eventLabel': postName
        }
        break
      case 'chapter':
        eventData = {
          'eventLabel': `${gameTitle}::${postName}`
        }
        break
      case 'character':
        eventData = {
          'eventLabel': `${gameTitle}::${chapter}::${postName}`
        }
        break
      case 'internalLink':
        eventData = {
          'element': eventElement,
          'section': section
        }
        break
      case 'externalLink':
        eventData = {
          'element': eventElement,
          'eventLabel': eventLabel,
          'section': section
        }
        break
      case 'directLink':
        eventData = {
          'section': section,
          'eventLabel': `${gameTitle}::${platformType}::${vendor}`,
          'platformType': platformType,
          'vendor': vendor
        }
        break
      case 'form':
        eventData = {
          'section': section
        }
        break
      case 'purchaseFlow':
        switch (eventAction) {
          case 'buy game':
            eventData = {
              'section': section,
              'eventLabel': `${gameTitle}::${platformType}::${vendor}`,
              'title': eventTitle,
              'platformType': platformType,
              'vendor': vendor
            }
            break
          case 'buy arsenal':
            eventData = {
              'section': section,
              'eventLabel': `${gameTitle}::${platformType}::${vendor}`,
              'title': eventTitle,
              'platformType': platformType,
              'vendor': vendor
            }
            break
          case 'buy collection':
            eventData = {
              'section': section,
              'eventLabel': `${gameTitle}::${platformType}::${vendor}`,
              'title': eventTitle,
              'platformType': platformType,
              'vendor': vendor
            }
            break
          case 'buy bundle':
            eventData = {
              'section': section,
              'eventLabel': `${gameTitle}::${platformType}::${vendor}`,
              'title': eventTitle,
              'platformType': platformType,
              'vendor': vendor
            }
            break
          case 'pre-order':
            eventData = {
              'section': section,
              'eventLabel': `${gameTitle}::${platformType}::${vendor}`,
              'platformType': platformType,
              'vendor': vendor
            }
            break
          case 'whishlist':
            eventData = {
              'section': section,
              'eventLabel': `${gameTitle}::${platformType}::${vendor}`,
              'platformType': platformType,
              'vendor': vendor
            }
            break
          case 'buy chapter':
            let eventLabel = `${gameTitle}::${chapter}::${platformType}::${vendor}::${postName}`
            if (chapter === postName) {
              eventLabel = `${gameTitle}::${chapter}::${platformType}::${vendor}`
            }
            eventData = {
              'eventLabel': eventLabel,
              'platformType': platformType,
              'vendor': vendor
            }
            break
        }
        break
    }
    dataObject = {
      ...dataObject,
      ...eventData
    }
  }

  // Push data to GA
  if (process.env.GATSBY_GTM_ID && process.env.GATSBY_ENV !== 'dev') {
    dataLayer.push(dataObject)
  } else {
    console.log(dataObject)
  }
}


/**
 * Track page Views with Google Analytics
 * @param  {String} language   Page language
 * @param  {String} section    Section/page title
 * @param  {String} gameTitle  Website Game title
 * @param  {String} postName   Custom Post title
 */

export const dataView = (language, section, gameTitle, postName) => {
  const isBrowser = typeof window !== 'undefined'
  if (!isBrowser) {
    return;
  }
  window.dataLayer = window.dataLayer || []

  let dataObject = {
    'language': language,
    'section': section,
    'game': gameTitle
  }

  // Set the post type key and value
  let postNameKey = ''
  let postNameValue = ''
  if (postName && postName !== null) {
    postNameValue = postName
    if (section === 'chapters') {
      postNameKey = 'chapterName'
    } else if (section === 'characters') {
      postNameKey = 'characterName'
    }
  }
  if (postNameKey !== '' && postNameValue !== '') {
    let postTypeData = {
      [postNameKey]: postNameValue
    }
    dataObject = {
      ...dataObject,
      ...postTypeData
    }
  }

  // Push data to GA
  if (process.env.GATSBY_GTM_ID && process.env.GATSBY_ENV !== 'dev') {
    dataLayer.push(dataObject)
  } else {
    console.log(dataObject)
  }
}