import React, { useRef, useContext } from 'react'
import { motion, useScroll, useTransform, useSpring, useReducedMotion } from 'framer-motion'
import { StaticImage } from 'gatsby-plugin-image'
import { useInView } from 'react-intersection-observer'

import { GeolocalizationContext } from '@base/utils/contexts/geolocalization-context'
import Image from '@base/parts/image'
import Link from '@base/parts/link'
import { localizePath } from '@base/helpers/localization/localize'
import SocialLinks from '@base/elements/social-links'
import Ratings from '@base/elements/ratings'
import FooterNav from '@base/elements/nav/nav-footer'
import Copyrights from '@base/elements/copyrights'

import Stores from '@elements/store/store-buttons'

const Footer = ({ pageContext, theme, data }) => {
  if (!data) {
    return ''
  }
  const ref = useRef(null)
  const [refLoad, inViewLoad] = useInView({
    threshold: 0,
    triggerOnce: true
  })
  const { rating } = useContext(GeolocalizationContext)
  const [gameRating, setGameRating] = rating
  const footerNav = data.footerNavData
  const themeModifier = data.themeModifier ? data.themeModifier : 'none'
  const brand = data.brandData
  const footer = data.footerData
  const socialLinks = data.socialData
  const gameData = data.gameData

  const prefersReducedMotion = useReducedMotion()
  const { scrollYProgress } = useScroll({
    target: ref,
    offset: ["-80vh", "-60vh"]
  })
  const translateY = useSpring(scrollYProgress, {
    stiffness: 50,
    damping: 30,
    mass: 0.5
  })
  const contentAnim = useTransform(translateY, [0, 1], [100, 0])

  return (
    <footer ref={ref} className="text-base relative z-1 lg:-mt-52 box-border">
      <div ref={refLoad} className="overflow-hidden w-full h-2/3 lg:h-footer absolute pointer-events-none right-0 z-20 bottom-0">
        <motion.div
          style={{
            y: !prefersReducedMotion && contentAnim
          }}
          className="crop-footer-shape bg-secondary w-full h-full "
        >
          {inViewLoad && (
            <>
              <StaticImage
                src="../../assets/img/MyM-background-texture-orange.jpg"
                alt=""
                className="h-full w-auto gatsby-absolute pointer-events-none z-under top-0"
                backgroundColor="#FF6600"
                loading="lazy"
              />
              <StaticImage
                src="../../assets/img/MyM-texture-diagonal-stripe-reverse.png"
                alt=""
                className="h-auto w-32 lg:w-48 gatsby-absolute pointer-events-none top-16 right-0 rotate-180"
                loading="lazy"
              />
            </>
          )}
        </motion.div>
      </div>
      <div className="crop-footer lg:pt-24 relative z-1 bg-primary overflow-hidden">
        {inViewLoad && (
          <>
            <StaticImage
              src="../../assets/img/MyM-yellow-dust-texture.png"
              alt=""
              className="h-auto w-full gatsby-absolute gatsby-object-top pointer-events-none top-0 left-0 rotate-180"
              loading="lazy"
            />
            <StaticImage
              src="../../assets/img/MyM-background-texture-blue.jpg"
              alt=""
              className="h-full w-full gatsby-absolute gatsby-object-top pointer-events-none z-under top-12"
              backgroundColor="#004242"
              loading="lazy"
            />
          </>
        )}
        <div className="container flex flex-col items-center py-24 text-white">
          {!themeModifier.hideMainNav ? (
            <Link to={localizePath({ ...pageContext, slug: '' })}>
              {brand?.alternateLogo ? (
                <Image
                  media={brand?.alternateLogo}
                  className="w-96"
                />
              ) : (
                <Image
                  media={brand?.fullLogo}
                  className="w-96"
                />
              )}
            </Link>
          ) : (
            brand?.alternateLogo ? (
              <Image
                media={brand?.alternateLogo}
                className="w-96"
              />
            ) : (
              <Image
                media={brand?.fullLogo}
                className="w-96"
              />
            )
          )}
        </div>

        {/* BUY GAME */}
        {gameData && gameData.gameLinks !== null && (
          <div className="container flex flex-col justify-center items-center text-white mb-20">
            {footer && footer.storeTitle && (
              <h3 className="font-bold mb-12 text-center">
                {footer.storeTitle}
              </h3>
            )}
            {inViewLoad && (
              <Stores
                pageContext={pageContext}
                layout="icons"
                product="mym-base-game"
                gameData={gameData}
                className="p-0 m-0 flex justify-center"
                storeClassName="mb-12 lg:mb-8 xl:mb-4 inline-flex justify-center mx-2 sm:mx-8 md:mx-6 lg:mx-16 xl:mx-8"
              />
            )}
            <div className='h-10 md:h-18 md:h-16 md:h-14 md:h-12 hidden'></div>
          </div>
        )}

        {/* SOCIAL */}
        {socialLinks && socialLinks !== null && (
          <div className="container flex flex-col items-center text-white">
            {footer && footer.socialTitle && (
              <h3 className="font-bold mb-12 text-center">
                {footer.socialTitle}
              </h3>
            )}
            {inViewLoad && (
              <SocialLinks
                social={socialLinks}
                className="flex gap-x-8"
                iconClassName="social-links-item"
              />
            )}
          </div>
        )}

        {/* RATING */}
        <div className="container flex flex-col items-center border-t-2 py-24 text-white">
          {inViewLoad && (
            process.env.GATSBY_GEOLOCALIZATION && (
              <Ratings />
            )
          )}
        </div>
      </div>

      {/* FOOTER NAV */}
      <div
        className={`
        relative flex flex-col justify-center pt-12 pb-24 text-center lg:text-left
          ${theme && theme.FooterNav && (`
            ${theme.FooterNav.bg
              ? `bg-${theme.FooterNav.bg}`
              : 'bg-black'
            }
            ${theme.FooterNav.text && (`
              ${theme.FooterNav.text.default
                ? `text-${theme.FooterNav.text.default}`
                : 'text-white'
              }
            `)}
          `)}
        `}
      >
        <div className="container lg:px-0 relative z-[20]">
          {footerNav && footerNav !== null && (
            <FooterNav
              links={footerNav}
              theme={theme.FooterNav}
            />
          )}

          {gameRating?.code === 'cero' && (
            <>
              <div className="text-[11px] tracking-wider text-center uppercase leading-normal">
                <span className="block">{`"PlayStationmark", "PlayStation", "PlayStation5" and "PlayStation4" are registered trademarks or trademarks of Sony Interactive Entertainment Inc.`}</span>
                <span>{`Microsoft Xbox Series X, Xbox Series S, Xbox One and related Xbox logos are trademarks and/or registered trademarks of Microsoft Corporation and the Microsoft > group of companies.`}</span>
              </div>
              <Copyrights
                className="text-[11px] tracking-wider text-center uppercase leading-normal"
                text='Valve Corporation. Steam and the Steam logo are trademarks and/or registered trademarks of Valve Corporation in the U.S. and/or other countries.'
              />
            </>
          )}

          {footer && footer.legalText && (
            <Copyrights
              className="text-[11px] tracking-wider text-center uppercase leading-normal"
              startYear='2021'
              text={footer.legalText}
            />
          )}
        </div>
      </div>
    </footer>
  )
}

export default Footer
