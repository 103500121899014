// Promo Tag
// Display a single promotion tag based on a defined store and gameID
import React from 'react'
import { dateFormat } from '@base/utils/helpers/date-time'
import Markdown from '@base/parts/markdown'

const PromoTag = ({ data, display, label }) => {
  if (!data) {
    return ''
  }
  let promoValue = data

  // Don't display promo amount if no promo available
  // Display fallback text when display is set to text and the label property is set.
  if (promoValue.length < 1) {
    return (
      display === 'text' && label && (
        <Markdown children={label} />
      )
    )
  }

  // Find active promo when multiple promos are set
  let currentRunningPromo = promoValue
  if (promoValue.length > 1) {
    promoValue?.forEach((promo) => {
      if (promo.active) {
        currentRunningPromo = promo
      }
    })
  }
  if (!currentRunningPromo) {
    return ''
  }

  // Set promo tag data
  let promo = currentRunningPromo?.promo ? currentRunningPromo?.promo : currentRunningPromo?.[0]?.promo
  let promoText = currentRunningPromo?.label ? currentRunningPromo?.label : currentRunningPromo?.[0]?.label
  const validPromo =
    promo !== '0%' &&
    promo !== '0' &&
    promo !== '-0%' &&
    promo !== '' &&
    promo !== null
  let startDate = currentRunningPromo?.startDate ? currentRunningPromo?.startDate : currentRunningPromo?.[0]?.startDate
  let endDate = currentRunningPromo?.endDate ? currentRunningPromo?.endDate : currentRunningPromo?.[0]?.endDate
  let timezone = currentRunningPromo?.timezone ? currentRunningPromo?.timezone : currentRunningPromo?.[0]?.timezone
  let now = new Date()
  let currentDate = dateFormat(now, timezone)
  startDate = dateFormat(startDate, timezone)
  endDate = dateFormat(endDate, timezone)

  // Look if the game is in promotion in the current platform
  let currentTimeFrame = currentDate >= startDate && currentDate < endDate
  if (!currentTimeFrame) {
    return ''
  }

  return (
    validPromo &&
    (display && display === 'text' && promoText !== null ? (
      <Markdown children={promoText} />
    ) : (
      <span className='promo-tag relative w-20 h-20 flex justify-center items-center ml-6 pointer-events-none'>
        {promo}
      </span>
    ))
  )
}

export default PromoTag
