import React from 'react'
import { useInView } from 'react-intersection-observer'
import Button from '@base/parts/button'
import Image from '@base/parts/image'
import useWindowSize from '@base/utils/hooks/useWindowSize'

const EventBanner = (props) => {
  let isMobile = false
  const windowSize = useWindowSize()
  if (windowSize.width <= 991) {
    isMobile = true
  }
  if (!props.data || !props.data.enabled) {
    return <></>
  }
  const [refLoad, inViewLoad] = useInView({
    threshold: 0,
    triggerOnce: true,
  })

  return (
    <div
      className="event-banner gatsby-absolute righ-0 sticky left-0"
      style={{
        backgroundImage: `url(${props.data.backgroundImage.localFile.publicURL})`,
      }}
      ref={refLoad}
    >
      <div className="text">
        <h3 className="title">{props.data.title}</h3>
        <h3 className="description">{props.data.text}</h3>
      </div>

      {props.data.buttons.map((button, index) => (
        <>
          <Button
            key={`event-banner-btn-${button.label}-${index}`}
            id={`event-banner-btn-${button.label}-${index}`}
            link={button.link}
            trigger={button.trigger ? button.trigger : 'internalLink'}
            eventAction={button.eventAction || ''}
            eventType={
              button.eventCategory && button.eventCategory === 'buy'
                ? 'main'
                : 'form'
            }
            icon={button.icon}
            className={`${props.data.buttons.length == 1 ? 'only-one' : ''}`}
          >
            {button.buttonId && (
              <span className="hidden">{button.buttonId}</span>
            )}
            {button.smallIcon && isMobile && (
              <>
                <Image
                  media={button?.smallIcon?.localFile.publicURL}
                  key={`event-banner-img-${button.label}-${index}`}
                />
              </>
            )}
            {button.largeIcon && !isMobile && (
              <>
                <Image
                  media={button?.largeIcon?.localFile.publicURL}
                  key={`event-banner-img-${button.label}-${index}`}
                  className={`toggles-background gatsby-absolute gatsby-image-mask-small left-1/2 top-0 z-0 h-[120%] w-full -translate-x-1/2 p-0 lg:-top-48 lg:h-auto xxl:-top-80`}
                />
              </>
            )}
            {!button.largeIcon && !button.smallIconIcon && <>{button.label}</>}
          </Button>
        </>
      ))}
      {!isMobile && (
        <div className="animation-container h-100 w-100 gatsby-absolute">
          <div id="harveyWalk"></div>
        </div>
      )}
    </div>
  )
}
export default EventBanner
