import React from 'react'

import BuyGameSection from '@elements/store-buy-section'
import Newsletter from '@base/elements/newsletter'
import Section from '@base/layout/section'

const DynamicSections = ({ globalContext, data, styling, newsletter: NewsletterExtension, buygame: BuyGameExtension, banner: NotificationBanner }) => {
  const newsletterData = data.newsletterData ? data.newsletterData : null
  const ageGateData = data.ageGateData ? data.ageGateData : null
  const gameData = data.gameData ? data.gameData : null
  const stringsData = data.stringsData ? data.stringsData : null
  const dataLayer = globalContext.dataLayer ? globalContext.dataLayer : null
  const bannerData = data.bannerData ? data.bannerData : null

  // Per-page section disabling
  const disableBuyGameSection = (data.disableBuyGameSection || gameData === null) ? true : false
  const disableNewsletterSignup = (data.disableNewsletterSignup || newsletterData === null) ? true : false
  const disableNotificationBanner = (data.disableNotificationBanner || bannerData === null) ? true : false

  // Custom styles
  let buyGameSectionStyles = styling && styling.buyGameStyles && styling.buyGameStyles.Section ? styling.buyGameStyles.Section : undefined
  let newsletterSectionStyles = styling && styling.newsletterStyles && styling.newsletterStyles.Section ? styling.newsletterStyles.Section : undefined
  let newsletterFormStyles = styling && styling.newsletterStyles && styling.newsletterStyles.Form ? styling.newsletterStyles.Form : undefined

  // if (!disableBuyGameSection) {
  //   newsletterSectionStyles = {...newsletterSectionStyles, section: `${newsletterSectionStyles.section} buy-section-active`}
  // }

  return (
    <div className={`lang-${globalContext.locale}`} style={{ marginTop: '5rem' }}>
      {!disableNotificationBanner && (
        <NotificationBanner data={bannerData} />
      )}
      {!disableBuyGameSection && (
        <section
          className={`buy-game-section ${buyGameSectionStyles.section}`}
        >
          <BuyGameExtension />
          <BuyGameSection
            pageContext={globalContext}
            data={gameData}
            styling={buyGameSectionStyles}
            dataLayer={dataLayer !== null && dataLayer.buyGame ? dataLayer.buyGame : null}
          />
        </section>
      )}

      {!disableNewsletterSignup && (
        <>
          {newsletterData.anchorID && newsletterData.anchorID !== null && (
            <div id={newsletterData.anchorID} className="relative -top-24 lg:-top-96 pointer-events-none" />
          )}
          <Section
            name='newsletter'
            styling={newsletterSectionStyles}
            content={NewsletterExtension}
          >
            <Newsletter
              pageContext={globalContext}
              data={{ newsletterData, ageGateData }}
              strings={stringsData}
              styling={newsletterFormStyles}
              dataLayer={dataLayer !== null && dataLayer.newsletter ? dataLayer.newsletter : null}
              leadTracking={'mym-query-string'}
              list={'meetyourmaker'}
            />
          </Section>
        </>
      )}
    </div>
  )
}

export default DynamicSections
