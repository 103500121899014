import React from 'react'

import { getStoreInfo } from './get-stores'
import { getStoreInfoLegacy } from '@base/utils/legacy/helpers/purchase/get-stores'

import StoreLink from './store-link'
import StoreCategories from '@base/elements/store/store-categories'
import StorePurchaseStates from '@base/elements/store/store-purchase-states'

const storesData = require('@base/data/stores.json')

const Stores = (props) => {
  if (!props.gameData || props.gameData === null) {
    return ''
  }

  const product = props.product ? props.product : 'base-game'
  const pageContext = { ...props.pageContext, product }
  const gameData = props.gameData
  const gameLinks = gameData && gameData.gameLinks ? gameData.gameLinks : null

  // Set Custom Data Layer event data
  const eventAction = props.eventAction ? props.eventAction : null
  const eventTitle = props.eventTitle ? props.eventTitle : null
  const eventCategory = props.eventCategory ? props.eventCategory : null
  const eventElement = props.eventElement ? props.eventElement : null
  const eventType = props.eventType ? props.eventType : null
  const eventData = {
    eventAction,
    eventCategory,
    eventElement,
    eventTitle,
    eventType,
  }

  // Add Links to stores 
  const productLinks = gameLinks.find((e) => (e.gameID === product) || (e.gameId === product))
  const productPromos = gameData.promos ? gameData.promos : null
  const customStoresData = gameData.stores

  // Legacy function for legacy data scheme
  let storeLinks
  if (props.legacy) {
    storeLinks = getStoreInfoLegacy(productLinks, storesData, customStoresData, productPromos, pageContext.locale)
  } else {
    storeLinks = getStoreInfo(productLinks, storesData, customStoresData, productPromos, pageContext.locale)
  }

  // Regroup platforms by categories (PC, Console)
  if (props.layout === 'categories') {
    return (
      <StoreCategories
        pageContext={pageContext}
        layout='categories'
        data={storeLinks}
        eventData={eventData}
        className={`${props.storeClassName ? props.storeClassName : ''}`}
        logoSize={props.logoSize ? props.logoSize : 'small'}
      />
    )
  }

  // Regroup platforms by purchase states (Purchase, Pre-Purchase, Wishlist)
  if (props.layout === 'purchaseStates') {
    return (
      <StorePurchaseStates
        pageContext={pageContext}
        data={storeLinks}
        layout='list-state'
        eventData={eventData}
        strings={props.strings ? props.strings : null}
        className={`${props.className ? props.className : ''}`}
        storeClassName={`${props.storeClassName ? props.storeClassName : ''}`}
        logoSize={props.logoSize ? props.logoSize : 'large'}
      />
    )
  }

  // List all platforms in a list
  if (props.layout === 'list') {
    return (
      <ul className={`store-list ${props.className ? props.className : 'flex flex-col'}`}>
        {storeLinks.map((store, i) => (
          <StoreLink
            key={i}
            index={i}
            pageContext={pageContext}
            layout='list'
            data={store}
            eventData={eventData}
            className={`${props.storeClassName ? props.storeClassName : ''}`}
            logoSize={props.logoSize ? props.logoSize : 'small'}
          />
        ))}
      </ul>
    )
  }

  // Display all platforms in buttons
  if (props.layout === 'cta') {
    return (
      <div className={`store-ctas ${props.className ? props.className : 'flex flex-row'}`}>
        {storeLinks.map((store, i) => (
          <StoreLink
            key={i}
            index={i}
            pageContext={pageContext}
            layout='cta'
            data={store}
            eventData={eventData}
            className={`${props.storeClassName ? props.storeClassName : ''}`}
            logoSize={props.logoSize ? props.logoSize : 'large'}
          >
            {props.children ? props.children : ''}
          </StoreLink>
        ))}
      </div>
    )
  }

  // Display All platforms logos
  return (
    <ul className={`store-list ${props.className ? props.className : ''}`}>
      {storeLinks.map((store, i) => (
        <StoreLink
          key={i}
          index={i}
          pageContext={pageContext}
          layout={props.layout ? props.layout : 'icons'}
          data={store}
          eventData={eventData}
          className={`${props.storeClassName ? props.storeClassName : ''}`}
          logoSize={props.logoSize ? props.logoSize : 'large'}
        />
      ))}
    </ul>
  )
}

export default Stores
