import React, { useEffect, useContext, useCallback } from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import SVG from 'react-inlinesvg'

import useLockBodyScroll from '@base/utils/hooks/useLockBodyScroll'
import closeIcon from '@base/assets/icons/close.svg'
import Image from '@base/parts/image'
import Newsletter from '@base/elements/newsletter'

import { NewsletterContext } from '@elements/newsletter/newsletter-context'

const NewsletterModal = (props) => {
  const { newsletterModal, email } = useContext(NewsletterContext)
  const [newsletterModalOpen, setNewsletterModalOpen] = newsletterModal
  const [emailValue, setEmailValue] = email
  const pageContext = props.pageContext
  const globalData = props.globalData
  const theme = pageContext?.theme?.siteTheme?.content?.Newsletter
  const newsletterFormStyles = theme?.Form ? theme.Form : undefined
  const newsletterData = globalData?.globalData?.newsletterForm

  // Age Gate data
  const ageGateData = globalData?.ageGateData
  const error = globalData?.stringsData?.errors
  const dates = globalData?.stringsData?.dates

  // Close modal box
  const close = useCallback(() => {
    setNewsletterModalOpen(false)
  }, [])

  const handleEscape = useCallback(event => {
    if (event.keyCode === 27) {
      close()
    }
  }, [close])

  useEffect(() => {
    if (newsletterModalOpen) document.addEventListener('keydown', handleEscape, false)
    return () => {
      document.removeEventListener('keydown', handleEscape, false)
    }
  }, [handleEscape, newsletterModalOpen])

  // Lock <body> scroll when modal is open
  useEffect(() => {
    if (newsletterModalOpen) {
      useLockBodyScroll(true, true)
    } else {
      useLockBodyScroll(false, true)
    }
  }, [newsletterModalOpen])

  return (
    <AnimatePresence>
      {newsletterModalOpen && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.5 }}
          className={`modal modal-newsletter z-[9999] flex items-center justify-center fixed top-0 left-0 h-screen w-screen backdrop-blur-md bg-black/80 ${newsletterModalOpen ? 'modal-open' : ''}`}
        >
          <div
            className={`absolute top-0 z-0 cursor-pointer h-screen w-screen left-0 p-10`}
            onClick={close}
          />
          <div className="h-full lg:h-auto max-h-screen overflow-auto relative text-white order-t m-auto flex w-full md:w-3/4 xl:w-[1080px] flex-col py-12 xl:py-24 px-10 xl:px-32 lg:justify-between z-1 bg-black lg:border-solid lg:border-3 lg:border-secondary">
            <button
              className="button-close duration-2500 absolute right-14 top-14 z-10 mt-2 flex h-14 w-14 items-center justify-center transition-all cursor-pointer"
              aria-label="close"
              onClick={close}
            >
              <SVG
                onError={error => console.log(error.message)}
                cacheRequests={true}
                preProcessor={code =>
                  code.replace(/fill=".*?"/g, 'fill="currentColor"')
                }
                src={closeIcon}
                className="absolute z-10 h-5 w-5"
              />
            </button>
            <div className="flex justify-center mb-12 lg:mb-20">
              <Image
                media={globalData?.brandData?.fullLogo}
                className="w-[13rem] lg:w-[22rem] xxl:w-[32rem] h-auto fill-white"
              />
            </div>
            <Newsletter
              pageContext={pageContext}
              strings={{ error, dates }}
              data={{ newsletterData, ageGateData }}
              styling={newsletterFormStyles}
              emailValue={emailValue}
              list={'meetyourmaker'}
            />
            {newsletterData?.backgroundImage && (
              <Image
                media={newsletterData.backgroundImage}
                className={`modal-newsletter-background gatsby-absolute top-0 left-0 h-full w-full z-under pointer-events-none opacity-50`}
              />
            )}
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  )
}

export default NewsletterModal
