import React from 'react'
import { navigate } from 'gatsby'
import { AnimatePresence } from 'framer-motion'

import useLocalStorage from '@base/hooks/useLocalStorage'
import LoaderContextProvider from '@base/utils/contexts/loader-context'

import Loader from '@elements/loader'
import Layout from '@layout/layout'
import { getContextData } from '@api/context-data'
import { getAllData } from '@api/all-data'

const themeStyle = require('@base/assets/styles/global.scss')
const globalStyle = require('@/assets/styles/global.scss')

// Page Layout
export const wrapPageElement = ({ element, props }) => {
  const browser = typeof window !== 'undefined' && window
  if (!props || !element || !browser) {
    return null
  }

  let pageData = props?.data
  if (!props.data || !props.data.pageData) {
    pageData = { pageData: props?.pageContext?.content }
  }
  if (!pageData || !pageData.pageData) {
    return element
  }

  // Remove parameters once consumed and add them to localStorage
  const queryString = props?.location?.search
  if (queryString && queryString !== null) {
    useLocalStorage('set', 'mym-query-string', queryString)
    //window.history.replaceState({}, document.title, '' + props.location.pathname)
  }

  // Setup Global website data (data that will be used in layout components like header and footer)
  const contextData = getContextData(props.pageContext, pageData)
  const generalData = getAllData(props.pageContext.globalData)

  return (
    <LoaderContextProvider>
      <Layout
        data={pageData}
        globalData={generalData}
        pageContext={contextData}
      >
        <AnimatePresence mode='wait'>
          {element}
        </AnimatePresence>
        <Loader pageContext={props.pageContext} />
      </Layout>
    </LoaderContextProvider>
  )
}

// Scroll Update on Route change
export const shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition
}) => {
  window.setTimeout(() => window.scrollTo(0, 0), 600)
  if (location.action === 'PUSH') {
    window.setTimeout(() => window.scrollTo(0, 0), 600)
  } else {
    // if we used the browser's forwards or back button
    const savedPosition = getSavedScrollPosition(location) || [0, 0]
    window.setTimeout(() => window.scrollTo(...savedPosition), 600)
  }
  return false
}