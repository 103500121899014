import React from 'react'
import { useInView } from 'react-intersection-observer'
import Markdown from '@base/parts/markdown'
import Stores from '@base/elements/store/store-buttons'
import Button from '@base/parts/button'
import MyMkeyart from '@/assets/videos/MyM-keyart-animated.mp4'
import MyMkeyartStatic from '@img/MyM-keyart-static.jpg'

const StoreBuySection = ({ pageContext, data, styling }) => {
  if(!data.buyGameSection) {
    return ''
  }
  
  const [ref, inView] = useInView({
    threshold: 0.5
  })

  const baseKeyart = {
    url: MyMkeyart,
  }
  const gameData = data
  const buyGameSectionData = data.buyGameSection
  const storesData = data.stores ? data.stores : null

  // Style the form with CSS classes
  const styles = {
    title: styling && styling.title ? styling.title : '',
    text: styling && styling.text ? styling.text : '',
    stores: styling && styling.stores ? styling.stores : '',
  }

  return (
    <div 
      ref={ref}
      style={{
        opacity: inView ? 1 : 0,
        transition: 'all 0.5s'
      }}
      className="container flex flex-col justify-center items-center"
    >
      {buyGameSectionData.title &&
        <h2 className={`buy-game-title ${styles.title}`}>
          {buyGameSectionData.title}
        </h2>
      }
      {buyGameSectionData.text &&
        <Markdown
          children={buyGameSectionData.text}
          className={`buy-game-text ${styles.text}`}
        />
      }
      {buyGameSectionData.displayStores && storesData !== null &&
        <Stores
          id="buy-game-section"
          pageContext={pageContext}
          location="buyGameSection"
          stores={storesData}
          className={`buy-game-stores ${styles.stores}`}
        />
      }
      {buyGameSectionData.buttons && buyGameSectionData.buttons.map(button => (
        <Button
          id={button.buttonId}
          key={button.strapi_id}
          link={button.link ? button.link : '#'}
          trigger={button.trigger ? button.trigger : 'internalLink'}
          style={`${button.style ? button.style : null} flex`}
          icon={button.icon ? button.icon : null}
          pageContext={{...pageContext, gameData}}
          eventCategory={button.eventCategory}
          eventAction={button.eventAction ? button.eventAction : ''}
          eventType={button.eventCategory && button.eventCategory === 'buy' ? 'main' : 'form'}
          eventLocation='buy game section'
          modalContent={
            button.trigger === 'buyModal'
              ? {
                media: baseKeyart,
                videoPoster: MyMkeyartStatic,
                logo: pageContext.brandData.fullLogo,
              }
              : ''
          }
        >
          {button.buttonId && (
            <span className="hidden">{button.buttonId}</span>
          )}
          <Markdown children={button.label} />
        </Button>
      ))}
    </div>
  )
}

export default StoreBuySection
