import React, { useState, useEffect, useContext } from 'react'
import { StaticImage } from 'gatsby-plugin-image'

import { localizePath } from '@base/helpers/localization/localize'
import Markdown from '@base/parts/markdown'
import LocaleSwitch from '@base/elements/locale-switch'
import SocialLinks from '@base/elements/social-links'
import MainNav from '@base/elements/nav/nav-main'
import Image from '@base/parts/image'
import Link from '@base/parts/link'
import Button from '@base/parts/button'
import NavUtilsNew from '@base/elements/nav/nav-utils-new'

import MyMkeyart from '@/assets/videos/MyM-keyart-animated.mp4'
import MyMkeyartStatic from '@img/MyM-keyart-static.jpg'

const Header = ({ pageContext, data, theme, children }) => {
  if (!data) {
    return ''
  }

  const [scrolled, setScrolled] = useState(false)
  const themeModifier = data.themeModifier ? data.themeModifier : 'none'
  const socialLinks = data.socialData
  const mainNav = data.mainNavData
  const ctaLinks = data.ctaData ? data.ctaData : null
  const brand = data.brandData
  const baseKeyart = {
    url: MyMkeyart,
  }
  const staticBaseKeyart = {
    url: MyMkeyartStatic,
  }
  const baseLogo = {
    url: brand?.fullLogo?.localFile?.publicURL ? brand?.fullLogo.localFile.publicURL : '',
  }

  // Stick main nav to top on scroll
  const handleScroll = () => {
    const offset = window.scrollY
    if (offset >= 250) {
      setScrolled(true)
    } else {
      setScrolled(false)
    }
  }
  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
  })

  return (
    <header className={`header-main pointer-events-none sticky top-0 z-[9999] transition-all`}>
      {!themeModifier.hideMainNav && (
        <NavUtilsNew
          locale={pageContext.locale}
          project="mym"
          className="pointer-events-auto relative flex h-16 flex-row items-center justify-end bg-black px-5 py-2 lg:h-[55px] lg:px-8 lg:py-4"
        />
      )}
      <div
        className={`nav nav-${pageContext.locale} pointer-events-auto relative flex h-[45px] justify-between bg-secondary pl-10 lg:h-32 lg:pl-16 lg:pr-0 ${scrolled && !themeModifier.hideMainNav ? 'scrolled' : 'initial'
          } ${!themeModifier.hideMainNav ? 'pr-[48px]' : ''}`}
      >
        <div className="relative z-10 flex w-full items-center lg:w-auto">
          {!themeModifier.hideMainNav ? (
            <Link
              to={localizePath({ ...pageContext, slug: '' })}
              className="inline-flex drop-shadow-none transition-all duration-300 hover:drop-shadow-2xl"
            >
              <Image
                media={brand?.fullLogo}
                className={`main-logo w-[85px] text-white lg:w-64 ${scrolled ? 'scrolled' : 'initial'
                  }`}
              />
            </Link>
          ) : (
            <div className="inline-flex">
              <Image
                media={brand?.fullLogo}
                className={`w-[85px] text-white lg:w-64 ${scrolled ? 'scrolled' : 'initial'
                  }`}
              />
            </div>
          )}
        </div>

        {!themeModifier.hideMainNav && (
          <MainNav
            links={mainNav}
            brand={brand}
            theme={theme && theme.MainNav !== null ? theme.MainNav : null}
            disableAutoActive={true}
            pageContext={pageContext}
            className="absolute left-0 z-1 h-full w-full list-none justify-between border-0 text-center font-display text-md tracking-wider transition-all lg:relative lg:flex lg:w-auto lg:flex-row lg:items-center lg:px-12 lg:text-base xl:w-auto xl:text-md"
          >
            <div className="flex border-0 border-b-2 border-solid border-black lg:hidden">
              {pageContext.localizations && (
                <LocaleSwitch
                  className="py-8 pl-20 pr-12 font-display text-md tracking-wider text-white"
                  pageContext={pageContext}
                />
              )}
            </div>
            <div className="sticky-top-mobile sticky flex flex-col items-center space-y-8 overflow-hidden p-10 pt-20 lg:hidden">
              {ctaLinks !== null && ctaLinks.map((ctaLink, i) => (
                <Button
                  key={i}
                  id={ctaLink.buttonId}
                  link={ctaLink.link}
                  trigger={ctaLink.trigger}
                  size="lg"
                  style={ctaLink.style}
                  icon={ctaLink.icon}
                  pageContext={pageContext}
                  eventCategory={
                    ctaLink.eventCategory ? ctaLink.eventCategory : 'buy'
                  }
                  eventAction={ctaLink.eventAction ? ctaLink.eventAction : ''}
                  eventLabel={ctaLink.eventLabel}
                  eventElement={ctaLink.eventElement}
                  eventType={
                    ctaLink.eventCategory === 'buy'
                      ? ctaLink.trigger === 'internalLink'
                        ? 'internalLink'
                        : 'externalLink'
                      : 'form'
                  }
                  modalContent={
                    ctaLink.trigger === 'buyModal'
                      ? {
                        media: baseKeyart,
                        videoPoster: MyMkeyartStatic,
                        logo: brand?.fullLogo,
                      }
                      : ''
                  }
                >
                  {ctaLink.buttonId && (
                    <span className="hidden">{ctaLink.buttonId}</span>
                  )}
                  <Markdown children={ctaLink.title} />
                  {ctaLink.trigger && ctaLink.trigger === 'externalLink' && (
                    <StaticImage
                      src="../../assets/icons/steam.svg"
                      alt="Steam"
                      className="gatsby-hidden-mobile ml-6 h-10 w-10"
                    />
                  )}
                </Button>
              ))}
              <StaticImage
                src="../../assets/img/MyM-texture-diagonal-stripe-large.png"
                alt=""
                className="gatsby-absolute gatsby-object-contain pointer-events-none -bottom-24 left-0 z-0 h-[300px] w-full"
                loading="lazy"
              />
            </div>
          </MainNav>
        )}
        <div
          className={`nav-overlay absolute left-0 z-under h-0 w-full bg-black/90 transition-all duration-300 lg:shadow-md`}
        ></div>

        <div className="flex h-full w-auto flex-row items-center justify-end">
          {!themeModifier.hideMainNav && (
            <>
              {socialLinks && socialLinks !== null && (
                <SocialLinks
                  social={socialLinks}
                  className="flex gap-x-8"
                  iconClassName="social-links-item-round"
                  display={'X'}
                />
              )}
            </>
          )}
          {pageContext?.localizations?.data?.length > 0 && (
            <li
              key="mainnav-language"
              className="nav-item relative z-1 hidden h-full flex-col items-center justify-between px-8 text-md text-white lg:flex"
            >
              <LocaleSwitch
                className="text-white"
                pageContext={pageContext}
              />
            </li>
          )}

          {ctaLinks !== null &&
            ctaLinks.map(
              (ctaLink, i) =>
                !ctaLink.mobileOnly && (
                  <>
                    {pageContext.postType !== 'landing-page' && (
                      <Button
                        key={i}
                        id={ctaLink.buttonId}
                        link={ctaLink.link}
                        trigger={ctaLink.trigger}
                        size="large"
                        icon={ctaLink.icon}
                        pageContext={pageContext}
                        eventCategory={
                          ctaLink.eventCategory ? ctaLink.eventCategory : ''
                        }
                        eventAction={
                          ctaLink.eventAction ? ctaLink.eventAction : ''
                        }
                        eventLabel={ctaLink.eventLabel}
                        eventElement={ctaLink.eventElement}
                        eventType={
                          ctaLink.eventCategory === 'buy'
                            ? ctaLink.trigger === 'internalLink'
                              ? 'internalLink'
                              : 'externalLink'
                            : 'form'
                        }
                        modalContent={
                          ctaLink.trigger === 'buyModal'
                            ? {
                              media: baseKeyart,
                              videoPoster: MyMkeyartStatic,
                              logo: brand?.fullLogo,
                            }
                            : ''
                        }
                      >
                        {ctaLink.buttonId && (
                          <span className="hidden">{ctaLink.buttonId}</span>
                        )}
                        <Markdown children={ctaLink.title} />
                        {ctaLink.trigger &&
                          ctaLink.trigger === 'externalLink' && (
                            <StaticImage
                              src="../../assets/icons/steam.svg"
                              alt="Steam"
                              className="gatsby-hidden-mobile ml-6 h-10 w-10"
                            />
                          )}
                      </Button>
                    )}

                    {pageContext.postType === 'landing-page' && (
                      <Button
                        link="mym-base-game"
                        trigger="buyModal"
                        size="large"
                        pageContext={pageContext}
                        className="landing-cta cursor-pointer"
                        eventCategory="buy"
                        eventAction="buy game intent"
                        eventType="main"
                        eventElement="mainNav"
                        modalContent={{
                          media: staticBaseKeyart,
                          logo: baseLogo,
                          title: data.stringsData.cta.selectPlatform,
                          eventElement: 'mainNav',
                          storeLayout: 'list'
                        }}
                      >
                        <Markdown children={ctaLink.title} />
                      </Button>
                    )}
                  </>
                )
            )}
        </div>
        {/* DYNAMIC ZONE - For displaying custom content in header */}
        {children && children !== null && children}
      </div>
    </header>
  )
}

export default Header
