import { getDiscount } from './get-discount'

export function getStoreInfo(productLinks, storesData, customStoresData, productPromos, locale) {
  if (!storesData || storesData === null) {
    return ''
  }

  let product = productLinks && productLinks?.gameId ? productLinks.gameId : productLinks && productLinks?.gameID ? productLinks?.gameID : null
  let storeLinks = []
  storesData?.find((e) => e.locale == locale).stores?.map((store) => {
    const productLink = productLinks && productLinks.links ? productLinks.links.find((e) => e.platform == store.platformID) : null
    const link = productLink && productLink !== null ? productLink.link : null
    const purchaseState = productLink && productLink !== null && productLink.productState ? productLink.productState : 'released'

    if (link === null) {
      return ''
    }

    // Set default data for each stores
    let platformType = store.category

    let title = store.title
    let logoSmall = store.logoSmall ? store.logoSmall : null
    let logoLarge = store.logoLarge ? store.logoLarge : null
    let logoAlternate = store.logoAlternate ? store.logoAlternate : null
    let logoSmallSize = store.logoSmallSize ? store.logoSmallSize : null
    let logoLargeSize = store.logoLargeSize ? store.logoLargeSize : null

    // Use the project's custom data if available, or use the default store data
    const customData = customStoresData && customStoresData !== null ? customStoresData.find((e) => e.platformID == store.platformID) : null
    if (customData && customData !== null) {
      customData.category && (
        platformType = customData.category
      )
      customData.title && (
        title = customData.title
      )
      customData.logoSmall && (
        logoSmall = customData.logoSmall
      )
      customData.logoLarge && (
        logoLarge = customData.logoLarge
      )
      customData.logoAlternate && (
        logoAlternate = customData.logoAlternate
      )
      customData.logoSmallSize && (
        logoSmallSize = customData.logoSmallSize
      )
      customData.logoLargeSize && (
        logoLargeSize = customData.logoLargeSize
      )
    }

    // Set active promotions to product
    const promos = productPromos && productPromos !== null ? productPromos : null
    let promoValue = null
    if (promos !== null) {
      let discounts = promos.discounts
      //let freeWeekEnds = promos.freeWeekEnds
      promoValue = getDiscount(store.platformID, product, discounts)
    }

    const storeLink = {
      platformID: store.platformID,
      platformType: platformType,
      purchaseState: purchaseState,
      title: title,
      link: link,
      logoSmall: logoSmall,
      logoLarge: logoLarge,
      logoAlternate: logoAlternate,
      logoSmallSize: logoSmallSize,
      logoLargeSize: logoLargeSize,
      promotion: promoValue
    }
    storeLinks.push({ ...storeLink })
  })

  return storeLinks
}
