import React, { useContext, useEffect } from 'react'
import { CookiesProvider } from 'react-cookie'
import { StaticImage } from 'gatsby-plugin-image'

import { LoadingContext } from '@base/utils/contexts/loader-context'
import PageContextProvider from '@base/utils/contexts/page-context'
import CountryLegalSettings from '@base/utils/helpers/geolocalization/country-legal-settings'
import FrameworkTools from '@base/utils/dev/dev-tools'
import NotificationBanner from '@base/elements/notification-banner'
import TwitchPlayer from '@base/elements/twitch-player'

import Header from './header'
import Footer from './footer'
import DynamicSections from './dynamic'
import NewsletterExtension from '@elements/newsletter/newsletter'
import BuyGameExtension from '@elements/buy-game-section'
import { getPageLocalizations } from '@/utils/helpers/localizations.js'
import EventBanner from '@elements/event-banner'
import NewsletterContextProvider from '@elements/newsletter/newsletter-context'
import NewsletterModal from '@elements/newsletter/newsletter-modal'

const Layout = (props) => {
  const pageContext = props.pageContext
  const dataLayer = props.dataLayer
  const theme = pageContext.theme ? pageContext.theme?.siteTheme : ''
  const globalData = props.globalData
  const pageData = props.data
  const staticData = pageContext.content
  const availableLocales = pageData ? pageData?.localizations : []
  const localizations = getPageLocalizations(availableLocales)
  const { locale, slug, defaultLocaleTitle, postType } = pageContext
  const { loading, contentLoaded, initLoading } = useContext(LoadingContext)
  const [isInitialLoading, setInitialLoading] = initLoading
  const [isLoading, setLoading] = loading
  const [isContentLoaded, setContentLoaded] = contentLoaded

  useEffect(() => {
    setInitialLoading(true)
  }, [])

  useEffect(() => {
    setContentLoaded(false)
    setLoading(true)
  }, [props.pageContext])

  // Site Custom theme
  const headerStyles = theme?.header ? theme?.header : undefined
  const footerStyles = theme?.footer ? theme?.footer : undefined
  const newsletterStyles = theme?.content?.Newsletter ? theme?.content.Newsletter : undefined
  const buyGameStyles = theme?.content?.BuyGame ? theme?.content.BuyGame : undefined
  const themeModifier = props.data?.themeModifier

  // General site data
  const brandData = globalData?.brandData
  const seoData = globalData?.seoData?.metadata
  const gameData = globalData?.gameData
  const socialData = globalData?.globalData?.socialLinks
  const ratingsData = globalData?.ratingsData
  const ageGateData = globalData?.ageGateData

  // Localized Strings Data
  const stringsData = globalData?.stringsData
  const pageDirectories = globalData?.directoriesData

  // Header specific data
  const mainNavData = globalData?.navData?.mainNav
  const ctaData = globalData?.navData?.ctaLinks

  // Dynamic sections specific data
  const newsletterData = globalData?.globalData?.newsletterForm
  const disableBuyGameSection = pageData?.pageData?.disableBuyGameSection ? pageData?.pageData?.disableBuyGameSection : staticData?.disableBuyGameSection ? staticData?.disableBuyGameSection : pageContext.postType === 'article' || pageContext.postType === 'error' ? true : false
  // const disableNewsletterSignup = pageData?.pageData?.disableNewsletterSignup ? pageData?.pageData?.disableNewsletterSignup : staticData?.disableNewsletterSignup ? staticData?.disableNewsletterSignup : false
  const disableNewsletterSignup = true
  const bannerData = globalData?.globalData?.notificationBanner;
  const eventBannerData = globalData?.globalData?.eventBanner;

  const Newsletter = () => {
    return <NewsletterExtension data={newsletterData} />
  }
  const BuyGame = () => {
    return <BuyGameExtension data={gameData.buyGameSection} />
  }
  const Banner = () => {
    return (
      <NotificationBanner
        data={bannerData}
        className="notification-banner-promo fixed right-0 z-40"
      />
    )
  }

  // Footer specific data
  const footerNavData = globalData?.navData && globalData?.navData.footerNav
  const footerData = globalData?.globalData?.footer

  return (
    <CookiesProvider>
      <PageContextProvider>
        <NewsletterContextProvider>
          <CountryLegalSettings data={ratingsData} />
          <Header
            data={{
              brandData,
              mainNavData,
              ctaData,
              stringsData,
              socialData,
              seoData,
              themeModifier,
              gameData,
            }}
            theme={headerStyles}
            pageContext={{ ...pageContext, pageDirectories }}
            themeModifier={{
              hideMainNav: themeModifier ? themeModifier.hideMainNav : false,
              storeCTALabel: themeModifier ? themeModifier.storeCTALabel : null,
              customTheme: themeModifier ? themeModifier.customTheme : false,
              customNavTitle: themeModifier
                ? themeModifier.customNavTitle
                : false,
            }}
          >
            <div className="absolute left-0 z-0 h-full w-full overflow-hidden">
              <StaticImage
                src="../../assets/img/MyM-background-texture-orange-small.jpg"
                alt=""
                className="h-full w-full"
              />
            </div>
            <StaticImage
              src="../../assets/img/MyM-texture-diagonal-stripe.png"
              alt=""
              className="nav-texture gatsby-absolute gatsby-hidden-mobile pointer-events-none left-[8%] h-full w-full lg:w-[370px]"
            />
            {eventBannerData?.enabled && <EventBanner data={eventBannerData} />}
          </Header>
          {props.children}
          <DynamicSections
            data={{
              gameData,
              ageGateData,
              bannerData,
              stringsData,
              disableBuyGameSection,
              disableNewsletterSignup,
              eventBannerData,
            }}
            globalContext={{
              locale,
              slug,
              localizations,
              postType,
              defaultLocaleTitle,
              brandData,
              dataLayer,
            }}
            styling={{ buyGameStyles }}
            buygame={BuyGame}
            banner={Banner}
          />
          <Footer
            data={{
              footerNavData,
              footerData,
              brandData,
              socialData,
              gameData,
            }}
            theme={footerStyles}
            pageContext={pageContext}
            customThemeOptions={{
              removeFooterSocial: themeModifier
                ? themeModifier.removeFooterSocial
                : false,
              removeFooterStoreLinks: themeModifier
                ? themeModifier.removeFooterStoreLinks
                : false,
              customTheme: themeModifier ? themeModifier.customTheme : false,
            }}
          />
          <NewsletterModal pageContext={pageContext} globalData={globalData} />
        </NewsletterContextProvider>
        {process.env.GATSBY_TWITCH_CHANNEL && (
          <TwitchPlayer
            channel={process.env.GATSBY_TWITCH_CHANNEL}
            name="MeetYourMakerGame"
          />
        )}
      </PageContextProvider>
      <FrameworkTools />
    </CookiesProvider>
  )
}

export default Layout
