/**
 * Format all general data
 */
import { getGameData } from './game-data'
import { getRatingData } from './rating-data'
import { getAgeGateData } from './age-gate-data'

export function getAllData(data) {
  if(!data) {
    return null
  }
  
  const gameData = getGameData(data)
  const ratingsData = getRatingData(data)
  const ageGateData = getAgeGateData(data)
  const brandData = data.brand.edges[0].node
  const globalData = data.global.edges[0].node
  const navData = data.navigation.edges[0].node
  const seoData = data.seo.edges[0].node
  const stringsData = data.strings.edges[0].node
  const directoriesData = data.pageDirectories.edges

  const generalData = { gameData, ratingsData, ageGateData, brandData, globalData, navData, seoData, stringsData, directoriesData }
  return generalData
}