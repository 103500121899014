import React, { createContext, useState } from 'react'

const defaultState = {
  formSubmitted: false,
  newsletterModalOpen: false,
  emailValue: false,
}
export const NewsletterContext = createContext(defaultState)

const NewsletterContextProvider = (props) => {
  const [formSubmitted, setFormSubmitted] = useState(false)
  const [newsletterModalOpen, setNewsletterModalOpen] = useState(false)
  const [emailValue, setEmailValue] = useState(false)

  return (
    <NewsletterContext.Provider
      value={{
        formSubmit: [formSubmitted, setFormSubmitted],
        newsletterModal: [newsletterModalOpen, setNewsletterModalOpen],
        email: [emailValue, setEmailValue],
      }}
    >
      {props.children}
    </NewsletterContext.Provider>
  )
}

export default NewsletterContextProvider
