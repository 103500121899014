/**
 * Get the discount value of a given product
 * @param  {Int}    platformID        ID of the targeted platform
 * @param  {Int}    gameID            ID of the targeted game
 * @param  {Object} discountPromos    List of discount promotions
 * @return {Object}                   The current promotion for the specific product
 */

import { dateFormat } from '@base/utils/helpers/date-time'

export function getDiscount(platformID, gameID, discountPromos) {
  if (!gameID || gameID === null) {
    return ''
  }

  let currentDiscount = []

  discountPromos.forEach((sale) => {
    if (sale.promos) {
      sale?.promos.forEach((promo) => {
        // Format the promo platform names to match Purchase flow data
        if (
          promo.platform !== null &&
          promo.promo !== null &&
          promo.startDate !== null &&
          promo.endDate !== null
        ) {

          let currentPlatform = promo.platform.toLowerCase()

          let now = new Date()
          let currentDate = dateFormat(now, promo.timezone)
          let startDate = dateFormat(promo.startDate, promo.timezone)
          let endDate = dateFormat(promo.endDate, promo.timezone)
          let currentTimeFrame = currentDate >= startDate && currentDate < endDate

          if (currentPlatform === 'windows') {
            currentPlatform = 'microsoft'
          }
          if (gameID === 'base-game') {
            // Apply promo to the main game
            if (sale.applyBaseGame) {
              if (currentPlatform === platformID) {
                let formatedPromo = {
                  platform: promo.platform,
                  promo: promo.promo,
                  label: promo.label,
                  startDate: promo.startDate,
                  endDate: promo.endDate,
                  timezone: promo.timezone,
                  active: currentTimeFrame,
                }
                if (currentTimeFrame) {
                  currentDiscount.push(formatedPromo)
                }
              }
            }
          } else {
            // Apply promo to all chapters
            if (sale.applyDefaultChapters) {
              if (currentPlatform === platformID) {
                let promoValue = promo.promo
                let activePromo =
                  currentDate >= startDate &&
                    currentDate < endDate &&
                    promoValue !== '0%' &&
                    promoValue !== '0'
                    ? true
                    : false

                // Lookup if current chapter has a custom promotion
                sale.customPromo.forEach((customPromo) => {
                  let chapterNumber = customPromo.chapter.chapterNumber
                  let chapterSlug = customPromo.chapter.slug.replaceAll('-', ' ')
                  gameID = gameID.replaceAll('-', ' ')
                  let formatedChapterName = `ch${chapterNumber} ${chapterSlug}`
                  if (gameID === formatedChapterName) {
                    promoValue = customPromo.promo
                    activePromo =
                      currentTimeFrame &&
                        promoValue !== '0%' &&
                        promoValue !== '0'
                        ? true
                        : false
                  }
                })
                let formatedPromo = {
                  platform: promo.platform,
                  promo: promoValue,
                  label: promo.label,
                  startDate: promo.startDate,
                  endDate: promo.endDate,
                  timezone: promo.timezone,
                  active: activePromo,
                }
                if (currentTimeFrame) {
                  currentDiscount.push(formatedPromo)
                }
              }
            } else {
              if (
                sale.targetChapters &&
                sale.targetChapters.chapterNumber !== null
              ) {
                sale.targetChapters.forEach((target) => {
                  if (currentPlatform === platformID) {
                    let chapterNumber = target.chapterNumber
                    let chapterSlug = target.slug.replaceAll('-', ' ')
                    let formatedChapterName = `ch${chapterNumber} ${chapterSlug}`
                    gameID = gameID.replaceAll('-', ' ')

                    if (gameID === formatedChapterName) {
                      let promoValue = promo.promo

                      // Lookup if current chapter has a custom promotion
                      sale.customPromo.forEach((customPromo) => {
                        let chapterNumber = customPromo.chapter.chapterNumber
                        let chapterSlug = customPromo.chapter.slug.replaceAll(
                          '-',
                          ' '
                        )
                        gameID = gameID.replaceAll('-', ' ')
                        let formatedChapterName = `ch${chapterNumber} ${chapterSlug}`
                        if (gameID === formatedChapterName) {
                          promoValue = customPromo.promo
                        }
                      })
                      let formatedPromo = {
                        platform: promo.platform,
                        promo: promoValue,
                        label: promo.label,
                        startDate: promo.startDate,
                        endDate: promo.endDate,
                        timezone: promo.timezone,
                        active: currentTimeFrame,
                      }
                      if (currentTimeFrame) {
                        currentDiscount.push(formatedPromo)
                      }
                    }
                  }
                })
              }
            }
          }
        }
      })
    } else {
      // Format the promo platform names to match Purchase flow data
      if (
        sale.platform !== null &&
        sale.promo !== null &&
        sale.startDate !== null &&
        sale.endDate !== null
      ) {
        let currentPlatform = sale.platform.toLowerCase()
        let now = new Date()
        let currentDate = dateFormat(now, sale.timezone)
        let startDate = dateFormat(sale.startDate, sale.timezone)
        let endDate = dateFormat(sale.endDate, sale.timezone)
        let currentTimeFrame = currentDate >= startDate && currentDate < endDate

        if (currentPlatform === 'windows') {
          currentPlatform = 'microsoft'
        }

        if (gameID === 'mym-base-game' && sale.applyBaseGame) {
          // Apply promo to the base game
          if (currentPlatform === platformID) {
            let formatedPromo = {
              platform: sale.platform,
              promo: sale.promo,
              label: sale.promo,
              startDate: sale.startDate,
              endDate: sale.endDate,
              timezone: sale.timezone,
              active: currentTimeFrame,
            }
            currentDiscount.push(formatedPromo)
          }
        }
        if (gameID === 'mym-deluxe-game' && sale.applyDeluxeGame) {
          // Apply promo to the deluxe game
          if (currentPlatform === platformID) {
            let formatedPromo = {
              platform: sale.platform,
              promo: sale.promo,
              label: sale.promo,
              startDate: sale.startDate,
              endDate: sale.endDate,
              timezone: sale.timezone,
              active: currentTimeFrame,
            }
            currentDiscount.push(formatedPromo)
          }
        }

        // Scorched Necropolis Promos
        sale?.dlcs.forEach((dlc) => {
          if (gameID.includes(dlc.slug)) {
            if (currentPlatform === platformID) {
              let formatedPromo = {
                platform: sale.platform,
                promo: sale.promo,
                label: sale.promo,
                startDate: sale.startDate,
                endDate: sale.endDate,
                timezone: sale.timezone,
                active: currentTimeFrame,
              }
              currentDiscount.push(formatedPromo)
            }
          }
        })
      }
    }
  })
  return currentDiscount
}
